.analytics-box {
  border-radius: 9px;
  background-color: black !important;
  padding: 0px !important;
  color: white;
  /* position: absolute;
  top: 1%;
  right: 1%; */
  z-index: 10000;
  text-align: center;
  margin: 0px 10px;
  height: 60%;
}

.analytics-detail-box {
  height: 85%;
  overflow: auto;
}

/* .analytics-box div {
  background-color: black !important;
} */

.analytics-box-heading {
  background: #2f2f2f;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #8a8c91;
}

.analytics-box h5 {
  font-size: 1em;
  font-weight: bold;
  padding: 10px;
  text-align: start;
}

.analytics-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  gap: 35px;
}

.analytics-details > div:first-child {
  text-align: left;
}

.current-date-details {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2em;
}

.analytics-details-header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 15px 0;
  gap: 5px;
  border: 2px solid #2f2f2f;
  background-color: #2f2f2f;
  flex: 3;
}

.analytics-details p {
  font-size: 0.8em;
  color: #ccc;
}

.analytics-details h2 {
  font-size: 1.2em;
}

.ab-status {
  /* color: red !important; */
  color: gold !important;
}

.ab-status-green {
  /* color: #4caf50 !important; */
  color: white !important;
}

.ab-close-btn {
  color: #eccf2d;
  font-size: 1em;
  cursor: pointer;
}
