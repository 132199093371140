.layer-box {
  border-radius: 9px;
  background-color: black !important;
  padding: 0px !important;
  color: white;
  z-index: 10000;
  text-align: center;
  margin: 0px 10px;
}

.layer-box-heading {
  background: #2f2f2f;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #8a8c91;
}

.layer-box h5 {
  font-size: 1em;
  font-weight: bold;
  padding: 10px;
  text-align: start;
}

.satelite-layer-switch {
  background-image: url("/public/Images/image.png");
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
}

.change-color {
  color: gold !important;
}
