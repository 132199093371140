.dataset-description {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
}

.icons-font {
  font-size: 1.2em;
  padding: 10px 0px !important;
}

/* .dataset-description h3 {
  font-size: 1em;
  margin: 0;
  padding: 0;
} */

.left-tooltip-box {
  position: absolute;
  left: 145%;
  z-index: 10000;
  display: inline-block;
  padding: 10px 15px;
  background-color: #2f2f2f; /* Dark background */
  color: white;
  font-size: 16px;
  border-radius: 8px;
  white-space: nowrap;
  font-size: 1em;
}

.left-tooltip-box::before {
  content: "";
  position: absolute;
  left: -10px; /* Adjusts position of the triangle */
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2f2f2f; /* Same color as background */
}

.dataset-description .unit {
  font-size: 0.8em;
  color: #f0c674;
  margin: 5px 0;
}

.dataset-description .value {
  font-size: 0.9em;
  color: #f0c674;
  margin-bottom: 5px;
}

.progress-bar-cover {
  border: 1px solid white;
  width: 143px;
  height: 10px;
  border-radius: 8px;
  margin-top: 10px;
  text-align: center;
  position: relative;
}

.progress-bar {
  position: absolute;
  height: 10px;
  background-color: gold;
  text-align: center;
  transition: width 1s ease-in-out;
  /* margin-top: 10px; */
  border-radius: 8px;
}
