@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
}

* {
  margin: 0;
}

#root {
  height: 100vh;
}
