.map-and-radio-panel {
  /* display: flex; */
  flex-direction: column;
  /* flex: 1; */
  height: 100%;
  justify-content: flex-end;
  position: relative;
}

.btns {
  /* align-self: flex-start; */
  width: max-content;
  /* align-self: flex-end;
  padding-right: 25px;
  margin-top: 15px; */
}

/* .normal-tasking {
  padding: 10px 15px;
  border: 1px solid gold;
  border-radius: 3px;
  color: gold;
  font-weight: bold;
} */

.priority-tasking {
  padding: 10px 15px;
  border-radius: 3px;
  background-color: gold;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
