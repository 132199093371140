.map-container {
  /* position: relative;
  height: 80%;
  padding: 25px 5%;
  border-radius: 8px; */
  top: 0;
  bottom: 0;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

.map-display {
  width: 100%;
  height: 100%;
  position: absolute !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

/* .map-display .leaflet-layer,
.map-display .leaflet-control-zoom-in,
.map-display .leaflet-control-zoom-out,
.map-display .leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
} */

.legend {
  position: absolute;
  top: 370px;
  right: 8px;
  padding: 10px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  font-size: 12px;
  z-index: 10000;
}

.legend h4 {
  margin: 0;
  padding-bottom: 5px;
  font-weight: bold;
}

/* .legend div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
} */

.legend span {
  display: inline-block;
  width: 20px;
  height: 10px;
  margin-right: 5px;
}

.blue-box {
  background-color: blue;
}
.pink-box {
  background-color: pink;
}
.orange-box {
  background-color: orange;
}
.line {
  width: 20px;
  height: 2px;
  background-color: black;
}
.fire-icon {
  font-size: 12px;
} /* Example for fire icon */
.brown-box {
  background-color: brown;
}
.black-white-scale {
  background: linear-gradient(to right, black, white);
  height: 10px;
}
.red-box {
  background-color: red;
}
.green-box {
  background-color: green;
}
.red-dot {
  background-color: red;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.Legend-colorBox {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  background-color: blue;
  border-radius: 2%;
  border-color: black;
}
.transparent-div {
  background-color: rgba(255, 255, 255, 0); /* Fully transparent */
}

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}