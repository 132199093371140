.radio-button-list {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #212121;
  /* width: 70%; */
  margin: auto;
  margin-bottom: 10px;
  border-radius: 8px;
  position: absolute;
  z-index: 1000;
  bottom: 0;
  left: 50%;
  translate: -50%;
}

.radio-button-list label {
  margin: 5px;
  color: white;
  display: flex;
  align-items: center;
}

.radio-button-list input {
  margin-right: 5px;
}

.line-btw-dots {
  width: 100%;
  height: 1px;
  background-color: gold;
  position: relative;
  margin-top: 10px;
  display: inline;
}

.timeline-container {
  display: flex;
  align-items: center;
  /* width: 90%; */
  /* max-width: 1000px; */
  /* margin: 0 auto; */
  min-width: 45vw;
}

.timeline-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
  cursor: pointer;
  gap: 10px;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #eccf2c;
  background-color: #121423;
  position: relative;
  z-index: 2;
}

.circle.active {
  background-color: #eccf2c;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: 37px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.timeline-item:first-child .line {
  width: 50%;
  left: 78%;
}

.timeline-item:last-child .line {
  width: 50%;
  right: 25%;
  left: auto;
}

.date {
  /* margin-top: 10px; */
  font-size: 14px;
  color: #ffffff;
}

.normal-tasking {
  border-radius: 4px;
  background-color: #ccc;
  color: black;
  font-weight: bold;
  padding: 10px 20px;
}
