.App {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  height: 100vh;
  overflow: hidden;
}

.main-nav {
  /* font-size: 28px; */
  /* font-weight: bold; */
  /* background-color: #212121; */
  padding: 15px 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 1000;
  left: 0;
  right: 0;
  /* position: absolute;
  top: 1%;
  right: 1%;
  z-index: 10000; */
}

.main-nav img {
  width: 100px;
}

.city-navigation-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  /* width: 100%; */
  background-color: #212121;
  border-top: 1px solid #666;
  border-bottom: 1px solid #666;
}

.left-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 30px;
}

.left-div > h3 {
  font-weight: bold;
  color: white;
  font-size: 30px;
}

.right-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 30px;
  position: relative;
}

.status-btn {
  display: block;
  padding: 8px 30px;
  border-radius: 3px;
  background-color: gold;
  color: black;
  font-weight: bold;
  cursor: pointer;
}

.block-btn {
  display: none;
}

.arrow {
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  padding: 10px;
}

.dots {
  margin: 0 10px;
  font-size: 1.2em;
}

.content {
  display: flex;
  width: 100vw;
  height: 100%;
  /* justify-content: space-between; */
}

.left-side {
  display: flex;
  flex-direction: column;
  /* width: 15%; */
  /* flex: 1; */
  /* border-right: 1px solid #666; */
  /* padding: 0 20px; */
  background-color: #212121;
  max-height: 100%;
  /* min-width: 20%  ; */
  /* overflow-y: auto; */
}

.right-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* width: 85%; */
  flex: 5;
  background-color: #121423;
}

/* Change this to SCSS style for global colors and designs */
.a {
  color: #fbd93a;
}

.popup {
  /* width: 400px; */
  margin: 0 auto;
  position: absolute;
  text-align: center;
  z-index: 1002;
  top: 85%;
  right: 2%;
  color: black;
  /* top: 90%;
  right: 4%; */
}
.popup:after {
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
}
.popup:before {
  content: "";
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
  background: #fff;
  position: absolute;
  z-index: 1002;
}
/*
Different arrow positioning
*/
.popup.arrow-top:before {
  left: calc(85% - 10px);
  top: -8px;
}
.popup.arrow-right:before {
  top: calc(50% - 10px);
  right: -8px;
}
.popup.arrow-bottom:before {
  left: calc(50% - 10px);
  bottom: -8px;
}
.popup.arrow-left:before {
  top: calc(50% - 10px);
  left: -8px;
}

/* 
Popup wrapper
This is where we add the white background and padding of the content within the popup
*/
.popup-wrapper {
  /* width: 100%; */
  padding: 28px;
  border-radius: 4px;
  background: #fff;
  position: relative;
  z-index: 1002;
}

.status_popup_close_btn {
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px;
  margin-top: 15px;
  cursor: pointer;
}

.popup-black {
  /* width: 400px; */
  margin: 0 auto;
  position: absolute;
  text-align: center;
  z-index: 1012;
  top: 85%;
  right: 2%;
  /* top: 90%;
  right: -4%;
  left: -300px; */
  background-color: #555;
  border-radius: 10px;
}
.popup-black:after {
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
}
.popup-black:before {
  content: "";
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
  background: #555;
  position: absolute;
  z-index: 1002;
}
/*
Different arrow positioning
*/
.popup-black.arrow-top-black:before {
  left: calc(85% - 10px);
  top: -8px;
}
.popup-black.arrow-right-black:before {
  top: calc(50% - 10px);
  right: -8px;
}
.popup-black.arrow-bottom-black:before {
  left: calc(50% - 10px);
  bottom: -8px;
}
.popup-black.arrow-left-black:before {
  top: calc(50% - 10px);
  left: -8px;
}

/* 
Popup wrapper
This is where we add the white background and padding of the content within the popup
*/
.popup-wrapper-black {
  /* width: 100%; */
  padding: 28px;
  border-radius: 10px;
  background: #555;
  position: relative;
  z-index: 1002;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-direction: column;
  color: white;
  font-size: 20px;
}

/* .popup-wrapper-black > div {
  width: 100%;
  border-bottom: 1px solid white;
} */

.popup-wrapper-black span {
  color: gold;
}

.image-insights {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  background-color: black;
  padding: 20px 20px;
  gap: 35px;
  /* width: 20vw; */
}

.image-insights > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 35px;
  width: 100%;
}

.disabled-status {
  background-color: #555;
  color: white;
}

.status-cls-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  font-size: 1.7em;
  color: white;
  font-weight: bold;
}
