.info-card-panel {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  gap: 10px; /* Adjust the gap between infocards to be smaller */
  /* padding: 10px 20px 10px 10px; */
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}

.collapsed {
  align-items: center !important;
  padding: 0px !important;
}

.logos {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid gray;
}

/* .info-card-panel img:first-child {
  width: 3.5vw;
} */

.info-card-panel img {
  width: 3.5em;
  align-self: center;
}

.info-card {
  /* flex: 0 1 calc(50% - 10px); Ensure two columns with minimal space */
  margin: 5px 0; /* Add a small margin to the top and bottom */
}
