.info-card {
  /* background-color: #3c3f41;
  border-radius: 10px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  color: #8a8c91;
  position: relative;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  /* width: 100%; /* Fixed width for each InfoCard */
  /* height: 135px; */
  /* padding: 10px 0; */
  cursor: pointer; /* Change cursor to pointer */
}

.info-card.selected-white {
  color: white !important;
}

.info-card.selected-red {
  color: #e53935 !important;
}

.info-card.selected-blue {
  color: #2473bd !important;
}

.info-card.selected-orange {
  color: #f8a200 !important;
}

.info-card:hover {
  cursor: pointer; /* Ensure cursor remains pointer on hover */
}
