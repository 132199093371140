.city-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.city-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
  background-color: #333;
  color: yellow;
}

.city-navigation .arrow {
  background: none;
  border: none;
  color: yellow;
  font-size: 1.5em;
  cursor: pointer;
  padding: 0 20px;
}

.city-navigation .dots {
  font-size: 1.5em;
  margin: 0 10px;
}

.city-navigation .arrow:hover {
  color: #f0c674;
}

.city-details {
  width: 100%;
}

.pt-popup-box {
  background-color: #121423;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: absolute;
  top: 45%;
  left: 52%;
  z-index: 1000;
  gap: 45px;
}

.cancel-btn {
  background-color: #f0f0f0;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
}

.task-btn {
  background-color: yellow;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
}

.pt-popup-box-msg {
  background-color: white;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: absolute;
  top: 35%;
  left: 30%;
  z-index: 1000;
  gap: 15px;
  color: black;
}

.msg-processing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 20px;
  margin-bottom: 10px;
}

.lds-spinner,
.lds-spinner div,
.lds-spinner div:after {
  box-sizing: border-box;
}
.lds-spinner {
  color: currentColor;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3.2px;
  left: 36.8px;
  width: 6.4px;
  height: 17.6px;
  border-radius: 20%;
  background: currentColor;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.icons-statsBox {
  /* position: absolute;
  top: 10%;
  right: 1%; */
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  background-color: #212121;
}

.right-most-nav {
  height: 100%;
  color: #ccc;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  /* justify-content: space-around; */
}

.detailed-view {
  height: 100%;
  width: 30%;
  align-items: flex-start !important;
}

.right-most-nav div {
  /* background: #212121; */
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.right-most-nav span {
  font-size: 30px;
}

.layer-activated {
  color: gold !important;
}

.right-tooltip-box {
  position: absolute;
  right: 125%;
  z-index: 10000;
  display: inline-block;
  padding: 10px 15px;
  background-color: #2f2f2f;
  color: white;
  font-size: 16px;
  border-radius: 8px;
  white-space: nowrap;
  font-size: 1em;
}

.right-tooltip-box::before {
  content: "";
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #2f2f2f;
}

.analytics-layer {
    position: relative !important;
}

.satelite-layer {
  position: relative !important;
}